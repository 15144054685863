body {
  font-family: 'Inter', sans-serif;
  color: #1a202e;
}

.gm-style-cc {
  display: none !important;
}

.gm-style a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.rdt input {
  outline: none;
}

.notifications-tc {
  width: 500px !important;
  margin: 0px auto 0px -250px !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
  max-height: 100vh;
}

.layout-side-bar {
  overflow-y: scroll !important;
}

.min-w-max {
  min-width: max-content;
}

.customModal {
  padding: 0 !important;
  margin: 0 !important;
  min-width: 100vw !important;
}
.react-responsive-modal-container {
  overflow-y: scroll !important;
}
.mr-2 .material-icons {
  font-size: 20px !important;
}
#scroll-view {
  scroll-behavior: smooth;
}
#scroll-slide-over {
  scroll-behavior: smooth;
}
.variants-table,
.catalog-variants-table {
  max-width: 78.5vw;
}
.prep-table,
.catalog-prep-table {
  max-width: 85.5vw;
}
.top {
  top: 115px;
  z-index: 9999;
}
.max-w {
  max-width: 100vw;
}
.w-table {
  max-width: 93.5vw;
}
.collapse-btn {
  width: fit-content;
}
.search-stock {
  width: 27vw;
}
@supports (-webkit-touch-callout: none) {
  .fix-height {
    height: -webkit-fill-available;
  }
}
@media (max-width: 700px) {
  .Vitally--reset .Vitally--NpsSurveyView-container {
    display: block !important;
  }
  .Vitally--reset .Vitally--ScorePicker-optionGroup {
    flex-wrap: wrap;
  }
  .Vitally--reset .Vitally--Popover-popoverPosition {
    top: -100%;
    left: 50%;
  }
  .Vitally--reset .red > .Vitally--Popover-popoverPosition {
    transform: translate(-50%, -50%) !important;
  }
}
@media (max-width: 768px) {
  .notifications-tc {
    width: 100% !important;
    margin: 0 !important;
    left: 0 !important;
  }
}

.notification {
  padding: 16px !important;
  background-color: #fff !important;
  border-top: 0 !important;
  color: #000 !important;
  font-size: 14px !important;
  display: flex !important;
  box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.16);
}

.notification-success {
  border-left: 4px solid #6db866;
}
.notification-warning {
  border-left: 4px solid #f57f17;
}
.notification-info {
  border-left: 4px solid #b7b9c4;
}
.notification-error {
  border-left: 4px solid #f95b45;
}

.notification-success:before {
  content: 'check_circle';
  font-family: 'Material Icons';
  margin-right: 8px;
  margin-top: -5px;
  font-size: 24px;
  color: #6db866;
}
.notification-warning:before {
  content: 'info';
  font-family: 'Material Icons';
  margin-right: 8px;
  margin-top: -5px;
  font-size: 24px;
  color: #f57f17;
}
.notification-info:before {
  content: 'info';
  font-family: 'Material Icons';
  margin-right: 8px;
  margin-top: -5px;
  font-size: 24px;
  color: #b7b9c4;
}

.notification-error:before {
  content: 'info';
  font-family: 'Material Icons';
  margin-right: 8px;
  margin-top: -5px;
  font-size: 20px;
  color: #f95b45;
}

.notification-message {
  padding-right: 20px !important;
}

.notification-dismiss {
  background-color: transparent !important;
  color: #b7b9c4 !important;
  top: 12px !important;
  right: 12px !important;
}

.notification-action-button {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
  opacity: 0;
}

.has-tooltip:hover .tooltip {
  visibility: visible;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.toggle-checkbox:checked {
  @apply right-0 border-primary-base;
  right: 0;
  border-color: #234cfb;
}

.toggle-checkbox:checked + .toggle-label {
  @apply bg-primary-base;
  background-color: #234cfb;
}

.form-input-error input {
  border-color: #d33d34 !important;
  background-color: #ffeff1;
}
.over-lay:hover ~ .delete-img {
  color: #e53e3e;
  visibility: visible;
}
.delete-img:hover {
  background-color: rgba(0, 0, 0, 0.5);
  visibility: visible;
  transition: 0.5s ease;
}
.delete-img:hover .delete-icon {
  visibility: visible;
}
.customClose {
  left: 10px !important;
  right: 14px;
}
input::placeholder,
textarea::placeholder {
  color: #97a6ba !important;
}
@media (min-width: 1024px) {
  .variants-table {
    max-width: 30vw;
  }
  .prep-table {
    max-width: 34vw;
  }
}
@media (min-width: 1280px) {
  .variants-table {
    max-width: 22vw;
  }
  .prep-table {
    max-width: 24.5vw;
  }
}

.leftIcon .beamer_icon.active {
  left: 0;
}
